

const languages = {
    en: {
        confirmationMessages: {
        title: 'Hello World',
        description: 'This is a simple example of a React app with multiple languages.',
        workTitle: 'Developer',
        cityName: 'Gothenburg',
        countryName: 'Sweden',
        aboutMeText: 'About Me',
        backgroundText:" Im a developer with a long background of database, backend and frontend development. I have worked with a lot of different technologies and languages over the years. I have a passion for learning new things and I am always looking for new challenges.",
        chatWithAnders: 'Chat with Anders Assistant',
        sendButtonText: 'Send',
        skillsText: 'Skills',
        projectsText: 'Projects',
        experienceText: 'Experience',
        photoGalleryText: 'Photo Gallery',
        recetProjectText:"Recent project - complete system www.bakesoft.se",
        crmText:"CRM - systems big companies",
        aiInfoText:"AI - information, conversion,agents and backend calls at OpenAi",
        hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
        hellstromInfo2:"Hellström Trading database development and frontend development",
        hellstromInfo3:"English teacher Japan 2016 - 2019",
        hellstromInfo4:"Office & buying manager Canton 2011-2014",
        hellstromInfo5:"Trend Interior -Data systems Manager 2004 - 2010",
        hellstromInfo6:"Stroede IT Development - System development & Project Manager  1999 - 2005",
        projectsText: 'Projects',
        photoGalleryText: 'Photo Gallery',
        weatherInText: 'Weather in',
        temperatureText: 'Temperature',
        conditiontext: 'Condition',
         assistantWelcomepGreetings: 'Hello, I am Anders Assistant. How can I help you today?',
   
   
    },
 },
    se: {
        confirmationMessages: {
        title: 'Hej Världen',
        description: 'Detta är ett enkelt exempel på en React-app med flera språk.',
        workTitle: 'Utvecklare',
        cityName: 'Göteborg',
        countryName: 'Sverige',
        aboutMeText: 'Om mig',
        backgroundText:"Jag är en utvecklare med en lång bakgrund av databas, backend och frontend-utveckling. Jag har arbetat med många olika teknologier och språk under åren. Jag har en passion för att lära mig nya saker och jag letar alltid efter nya utmaningar.",
        chatWithAnders: 'Chatta med Anders Assistent',
        sendButtonText: 'Skicka',
        skillsText: 'Kompetenser',
        projectsText: 'Projekt',
        experienceText: 'Erfarenhet',
        photoGalleryText: 'Fotogalleri',
        recetProjectText:"Senaste projekt - komplett system www.bakesoft.se",
        crmText:"CRM - system stora företag",
        aiInfoText:"AI - information, konvertering, agenter, beetalnings system",
        hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
        hellstromInfo2:"Hellström Trading databasutveckling och frontend-utveckling",
        hellstromInfo3:"Engelsklärare Japan 2016 - 2019",
        hellstromInfo4:"Kontors- och inköpschef Canton 2011-2014",
        hellstromInfo5:"Trend Interior -Data systems Manager 2004 - 2010",
        hellstromInfo6:"Stroede IT Development - Systemutveckling & Projektledare  1999 - 2005", 
        projectsText: 'Projekt',
        photoGalleryText: 'Fotogalleri',
        weatherInText: 'Väder i',
        temperatureText: 'Temperatur',
        conditiontext: 'Förhållande',
        assistantWelcomepGreetings:"Hej, jag är Anders online assistent, jag kan hjälpa dig med information om Anders Hellström, hans projekt och erfarenhet. Vad vill du veta om Anders?",
    
    },
},
   ja:{
    confirmationMessages: {
    title: 'こんにちは世界',
    description: 'これは、複数の言語を持つReactアプリの単純な例です。',
    workTitle: '開発者',
    cityName: 'ゴテボリ',
    countryName: 'スウェーデン',
    aboutMeText: '私について',
    backgroundText:"私はデータベース、バックエンド、フロントエンド開発の長い経歴を持つ開発者です。私はこれまでにさまざまな技術と言語を使ってきました。新しいことを学ぶことに情熱を持っており、常に新しい挑戦を探しています。",
    chatWithAnders: 'アンダースアシスタントとチャット',
    sendButtonText: '送信',
    skillsText: 'スキル',
    projectsText: 'プロジェクト',
    experienceText: '経験',
    photoGalleryText: 'フォトギャラリー',
    recetProjectText:"最近のプロジェクト -- 完全なシステム www.bakesoft.se",
    crmText:"CRM - システム大企業",
    aiInfoText:"AI - 情報、変換、エージェント、OpenAiでのバックエンドコール",
    hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
    hellstromInfo2:"Hellström Trading データベース開発とフロントエンド開発",
    hellstromInfo3:"英語教師 日本 2016 - 2019",
    hellstromInfo4:"オフィス＆バイイングマネージャー Canton 2011-2014",
    hellstromInfo5:"Trend Interior -データシステムマネージャー 2004 - 2010",
    hellstromInfo6:"Stroude IT Development - システム開発＆プロジェクトマネージャー  1999 - 2005",
    weatherInText: '天気',
    temperatureText: '温度',
    conditiontext: '状態',
     assistantWelcomepGreetings:"こんにちは、私はアンダースアシスタントです。今日はどうしたいですか？",
  


    },
},
    // Add more languages here
    dk:{
        confirmationMessages: {
        title: 'Hej Verden',
        description: 'Dette er et simpelt eksempel på en React-app med flere sprog.',
        workTitle: 'Udvikler',
        cityName: 'Göteborg',
        countryName: 'Sverige',
        aboutMeText: 'Om mig',
        backgroundText:"Jeg er en udvikler med en lang baggrund inden for database, backend og frontend-udvikling. Jeg har arbejdet med mange forskellige teknologier og sprog gennem årene. Jeg har en passion for at lære nye ting, og jeg leder altid efter nye udfordringer.",
        chatWithAnders: 'Chat med Anders Assistent',
        sendButtonText: 'Send',
        skillsText: 'Færdigheder',
        projectsText: 'Projekter',
        experienceText: 'Erfaring',
        photoGalleryText: 'Foto Galleri',
        recetProjectText:"Seneste projekt -komplet system www.bakesoft.se",
        crmText:"CRM - system store virksomheder",
        aiInfoText:"AI - information, konvertering, agenter og backend-opkald på OpenAi",
        hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
        hellstromInfo2:"Hellström Trading databaseudvikling og frontend-udvikling",
        hellstromInfo3:"Engelsklærer Japan 2016 - 2019",
        hellstromInfo4:"Kontor- og indkøbschef Canton 2011-2014",
        hellstromInfo5:"Trend Interior -Data systems Manager 2004 - 2010",
        hellstromInfo6:"Stroede IT Development - Systemudvikling & Projektleder  1999 - 2005", 
        projectsText: 'Projekter',
        photoGalleryText: 'Foto Galleri',
        weatherInText: 'Vejret i',
        temperatureText: 'Temperatur',
        conditiontext: 'Tilstand',
         assistantWelcomepGreetings:"Hej, jeg er Anders Assistent. Hvordan kan jeg hjælpe dig i dag?",
    
    },
},
    no:{
        confirmationMessages: {
        title: 'Hei Verden',
        description: 'Dette er et enkelt eksempel på en React-app med flere språk.',
        workTitle: 'Utvikler',
        cityName: 'Göteborg',
        countryName: 'Sverige',
        aboutMeText: 'Om meg',
        backgroundText:"Jeg er en utvikler med en lang bakgrunn innen database, backend og frontend-utvikling. Jeg har jobbet med mange forskjellige teknologier og språk gjennom årene. Jeg har en lidenskap for å lære nye ting, og jeg leter alltid etter nye utfordringer.",
        chatWithAnders: 'Chat med Anders Assistent',
        sendButtonText: 'Send',
        skillsText: 'Ferdigheter',
        projectsText: 'Prosjekter',
        experienceText: 'Erfaring',
        photoGalleryText: 'Foto Galleri',
        recetProjectText:"Siste prosjekt - komplett system www.bakesoft.se",
        crmText:"CRM - system store selskaper",
        aiInfoText:"AI - informasjon, konvertering, agenter og backend-anrop på OpenAi",
        hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
        hellstromInfo2:"Hellström Trading databaseutvikling og frontend-utvikling",
        hellstromInfo3:"Engelsklærer Japan 2016 - 2019",
        hellstromInfo4:"Kontor- og innkjøpssjef Canton 2011-2014",
        hellstromInfo5:"Trend Interior -Data systems Manager 2004 - 2010",
        hellstromInfo6:"Stroede IT Development - Systemutvikling & Prosjektleder  1999 - 2005", 
        projectsText: 'Prosjekter',
        photoGalleryText: 'Foto Galleri',
        weatherInText: 'Været i',
        temperatureText: 'Temperatur',
        conditiontext: 'Tilstand',
         assistantWelcomepGreetings:"Hei, jeg er Anders Assistent. Hvordan kan jeg hjelpe deg i dag?",
},
    },
    fr:{
        confirmationMessages: {
        title: 'Bonjour le monde',
        description: 'Ceci est un exemple simple d\'une application React avec plusieurs langues.',
        workTitle: 'Développeur',
        cityName: 'Göteborg',
        countryName: 'Suède',
        aboutMeText: 'À propos de moi',
        backgroundText:"Je suis un développeur avec une longue expérience dans le développement de bases de données, de back-end et de front-end. J'ai travaillé avec de nombreuses technologies et langages différents au fil des ans. J'ai une passion pour apprendre de nouvelles choses et je suis toujours à la recherche de nouveaux défis.",
        chatWithAnders: 'Discuter avec l\'assistant Anders',
        sendButtonText: 'Envoyer',
        skillsText: 'Compétences',
        projectsText: 'Projets',
        experienceText: 'Expérience',
        photoGalleryText: 'Galerie de photos',
        recetProjectText:"Projet récent - système complet www.bakesoft.se",
        crmText:"CRM - systèmes grandes entreprises",
        aiInfoText:"IA - informations, conversion, agents et appels backend chez OpenAi",
        hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
        hellstromInfo2:"Développement de bases de données et développement frontal Hellström Trading",
        hellstromInfo3:"Professeur d'anglais Japon 2016 - 2019",
        hellstromInfo4:"Directeur de bureau et d'achat Canton 2011-2014",
        hellstromInfo5:"Trend Interior -Data systems Manager 2004 - 2010",
        hellstromInfo6:"Stroede IT Development - Développement de systèmes et chef de projet  1999 - 2005", 
        projectsText: 'Projets',
        photoGalleryText: 'Galerie de photos',
        weatherInText: 'Météo à',
        temperatureText: 'Température',
        conditiontext: 'Condition',
         assistantWelcomepGreetings:"Bonjour, je suis l'assistant Anders. Comment puis-je vous aider aujourd'hui?",
}
    },
    fi:{
        confirmationMessages: {
        title: 'Hei maailma',
        description: 'Tämä on yksinkertainen esimerkki React-sovelluksesta, jossa on useita kieliä.',
        workTitle: 'Kehittäjä',
        cityName: 'Göteborg',
        countryName: 'Ruotsi',
        aboutMeText: 'Minusta',
        backgroundText:"Olen kehittäjä, jolla on pitkä tausta tietokanta-, tausta- ja frontend-kehityksestä. Olen työskennellyt monien eri teknologioiden ja kielten parissa vuosien varrella. Minulla on intohimo uusien asioiden oppimiseen ja etsin aina uusia haasteita.",
        chatWithAnders: 'Keskustele Andersin avustajan kanssa',
        sendButtonText: 'Lähetä',
        skillsText: 'Taidot',
        projectsText: 'Projektit',
        experienceText: 'Kokemus',
        photoGalleryText: 'Valokuvagalleria',
        recetProjectText:"Viimeisin projekti - täydellinen järjestelmä www.bakesoft.se",
        crmText:"CRM - järjestelmät suuret yritykset",
        aiInfoText:"AI - tiedot, muunnos, agentit ja taustapuhelut OpenAi:ssa",
        hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
        hellstromInfo2:"Hellström Trading tietokannan kehitys ja frontend-kehitys",
        hellstromInfo3:"Englannin opettaja Japani 2016 - 2019",
        hellstromInfo4:"Toimisto- ja ostosjohtaja Canton 2011-2014",
        hellstromInfo5:"Trend Interior -Data systems Manager 2004 - 2010",
        hellstromInfo6:"Stroede IT Development - Järjestelmäkehitys ja projektipäällikkö  1999 - 2005", 
        projectsText: 'Projektit',
        photoGalleryText: 'Valokuvagalleria',
        weatherInText: 'Sää',
        temperatureText: 'Lämpötila',
        conditiontext: 'Kunto',
         assistantWelcomepGreetings:"Hei, olen Andersin avustaja. Kuinka voin auttaa sinua tänään?",
    },
},
it:{
    confirmationMessages: {
    title: 'Ciao mondo',
    description: 'Questo è un esempio semplice di un\'app React con più lingue.',
    workTitle: 'Sviluppatore',
    cityName: 'Göteborg',
    countryName: 'Svezia',
    aboutMeText: 'Su di me',
    backgroundText:"Sono uno sviluppatore con una lunga esperienza nello sviluppo di database, backend e frontend. Ho lavorato con molte tecnologie e linguaggi diversi nel corso degli anni. Ho una passione per imparare cose nuove e sono sempre alla ricerca di nuove sfide.",
    chatWithAnders: 'Chatta con l\'assistente Anders',
    sendButtonText: 'Invia',
    skillsText: 'Competenze',
    projectsText: 'Progetti',
    experienceText: 'Esperienza',
    photoGalleryText: 'Galleria fotografica',
    recetProjectText:"Progetto recente - sistema completo www.bakesoft.se",
    crmText:"CRM - sistemi grandi aziende",
    aiInfoText:"IA - informazioni, conversione, agenti e chiamate backend su OpenAi",
    hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
    hellstromInfo2:"Sviluppo di database e sviluppo frontend Hellström Trading",
    hellstromInfo3:"Insegnante di inglese Giappone 2016 - 2019",
    hellstromInfo4:"Direttore ufficio e acquisti Canton 2011-2014",
    hellstromInfo5:"Trend Interior -Data systems Manager 2004 - 2010",
    hellstromInfo6:"Stroede IT Development - Sviluppo di sistemi e project manager  1999 - 2005", 
    projectsText: 'Progetti',
    photoGalleryText: 'Galleria fotografica',
    weatherInText: 'Meteo a',
    temperatureText: 'Temperatura',
    conditiontext: 'Condizione',
     assistantWelcomepGreetings:"Ciao, sono l'assistente di Anders. Come posso aiutarti oggi?",
},
},
span:{
    confirmationMessages: {
    title: 'Hola Mundo',
    description: 'Este es un ejemplo simple de una aplicación React con varios idiomas.',
    workTitle: 'Desarrollador',
    cityName: 'Göteborg',
    countryName: 'Suecia',
    aboutMeText: 'Sobre mí',
    backgroundText:"Soy un desarrollador con una larga experiencia en el desarrollo de bases de datos, backend y frontend. He trabajado con muchas tecnologías y lenguajes diferentes a lo largo de los años. Tengo pasión por aprender cosas nuevas y siempre estoy buscando nuevos desafíos.",
    chatWithAnders: 'Chatea con el asistente de Anders',
    sendButtonText: 'Enviar',
    skillsText: 'Habilidades',
    projectsText: 'Proyectos',
    experienceText: 'Experiencia',
    photoGalleryText: 'Galería de fotos',
    recetProjectText:"Proyecto reciente - sistema completo www.bakesoft.se",
    crmText:"CRM - sistemas grandes empresas",
    aiInfoText:"IA - información, conversión, agentes y llamadas de backend en OpenAi",
    hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
    hellstromInfo2:"Desarrollo de bases de datos y desarrollo frontend Hellström Trading",
    hellstromInfo3:"Profesor de inglés Japón 2016 - 2019",
    hellstromInfo4:"Gerente de oficina y compras Canton 2011-2014",
    hellstromInfo5:"Trend Interior -Data systems Manager 2004 - 2010",
    hellstromInfo6:"Stroede IT Development - Desarrollo de sistemas y jefe de proyecto  1999 - 2005", 
    projectsText: 'Proyectos',
    photoGalleryText: 'Galería de fotos',
    weatherInText: 'Clima en',
    temperatureText: 'Temperatura',
    conditiontext: 'Condición',
     assistantWelcomepGreetings:"Hola, soy el asistente de Anders. ¿Cómo puedo ayudarte hoy?",
},
},//germany
de:{
    confirmationMessages: {
    title: 'Hallo Welt',
    description: 'Dies ist ein einfaches Beispiel für eine React-App mit mehreren Sprachen.',
    workTitle: 'Entwickler',
    cityName: 'Göteborg',
    countryName: 'Schweden',
    aboutMeText: 'Über mich',
    backgroundText:"Ich bin ein Entwickler mit langjähriger Erfahrung in der Datenbank-, Backend- und Frontend-Entwicklung. Ich habe im Laufe der Jahre mit vielen verschiedenen Technologien und Sprachen gearbeitet. Ich habe eine Leidenschaft dafür, neue Dinge zu lernen, und bin immer auf der Suche nach neuen Herausforderungen.",
    chatWithAnders: 'Chatten Sie mit Anders Assistant',
    sendButtonText: 'Senden',
    skillsText: 'Fähigkeiten',
    projectsText: 'Projekte',
    experienceText: 'Erfahrung',
    photoGalleryText: 'Fotogalerie',
    recetProjectText:"Neuestes Projekt - vollständiges System www.bakesoft.se",
    crmText:"CRM - Systeme großer Unternehmen",
    aiInfoText:"KI - Informationen, Konvertierung, Agenten und Backend-Anrufe bei OpenAi",
    hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
    hellstromInfo2:"Hellström Trading Datenbankentwicklung und Frontend-Entwicklung",
    hellstromInfo3:"Englischlehrer Japan 2016 - 2019",
    hellstromInfo4:"Büro- und Einkaufsleiter Canton 2011-2014",
    hellstromInfo5:"Trend Interior -Data systems Manager 2004 - 2010",
    hellstromInfo6:"Stroede IT Development - Systementwicklung & Projektmanager  1999 - 2005", 
    projectsText: 'Projekte',
    photoGalleryText: 'Fotogalerie',
    weatherInText: 'Wetter in',
    temperatureText: 'Temperatur',
    conditiontext: 'Zustand',
     assistantWelcomepGreetings:"Hallo, ich bin Anders Assistant. Wie kann ich Ihnen heute helfen?",
},
},
//belgium
nl:{
    confirmationMessages: {
    title: 'Hallo Wereld',
    description: 'Dit is een eenvoudig voorbeeld van een React-app met meerdere talen.',
    workTitle: 'Ontwikkelaar',
    cityName: 'Göteborg',
    countryName: 'Zweden',
    aboutMeText: 'Over mij',
    backgroundText:"Ik ben een ontwikkelaar met een lange achtergrond in database-, backend- en frontend-ontwikkeling. Ik heb in de loop der jaren met veel verschillende technologieën en talen gewerkt. Ik heb een passie voor het leren van nieuwe dingen en ben altijd op zoek naar nieuwe uitdagingen.",
    chatWithAnders: 'Chat met Anders Assistant',
    sendButtonText: 'Verzenden',
    skillsText: 'Vaardigheden',
    projectsText: 'Projecten',
    experienceText: 'Ervaring',
    photoGalleryText: 'Fotogalerij',
    recetProjectText:"Recent project - compleet systeem www.bakesoft.se",
    crmText:"CRM - systemen grote bedrijven",
    aiInfoText:"AI - informatie, conversie, agenten en backend-oproepen bij OpenAi",
    hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
    hellstromInfo2:"Hellström Trading databaseontwikkeling en frontend-ontwikkeling",
    hellstromInfo3:"Engelse leraar Japan 2016 - 2019",
    hellstromInfo4:"Kantoor- en inkoopmanager Canton 2011-2014",
    hellstromInfo5:"Trend Interior -Data systems Manager 2004 - 2010",
    hellstromInfo6:"Stroede IT Development - Systeemontwikkeling & Projectmanager  1999 - 2005", 
    projectsText: 'Projecten',
    photoGalleryText: 'Fotogalerij',
    weatherInText: 'Weer in',
    temperatureText: 'Temperatuur',
    conditiontext: 'Staat',
     assistantWelcomepGreetings:"Hallo, ik ben Anders Assistant. Hoe kan ik u vandaag helpen?",
},
},
//portugal
pt:{
    confirmationMessages: {
    title: 'Olá Mundo',
    description: 'Este é um exemplo simples de um aplicativo React com vários idiomas.',
    workTitle: 'Desenvolvedor',
    cityName: 'Göteborg',
    countryName: 'Suécia',
    aboutMeText: 'Sobre mim',
    backgroundText:"Sou um desenvolvedor com uma longa experiência em desenvolvimento de banco de dados, backend e frontend. Trabalhei com muitas tecnologias e idiomas diferentes ao longo dos anos. Tenho paixão por aprender coisas novas e estou sempre em busca de novos desafios.",
    chatWithAnders: 'Converse com o assistente Anders',
    sendButtonText: 'Enviar',
    skillsText: 'Habilidades',
    projectsText: 'Projetos',
    experienceText: 'Experiência',
    photoGalleryText: 'Galeria de fotos',
    recetProjectText:"Projeto recente - sistema completo www.bakesoft.se",
    crmText:"CRM - sistemas grandes empresas",
    aiInfoText:"IA - informações, conversão, agentes e chamadas de backend no OpenAi",
    hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
    hellstromInfo2:"Desenvolvimento de banco de dados e desenvolvimento frontend Hellström Trading",
    hellstromInfo3:"Professor de inglês Japão 2016 - 2019",
    hellstromInfo4:"Gerente de escritório e compras Canton 2011-2014",
    hellstromInfo5:"Trend Interior -Data systems Manager 2004 - 2010",
    hellstromInfo6:"Stroede IT Development - Desenvolvimento de sistemas e gerente de projeto  1999 - 2005", 
    projectsText: 'Projetos',
    photoGalleryText: 'Galeria de fotos',
    weatherInText: 'Tempo em',
    temperatureText: 'Temperatura',
    conditiontext: 'Condição',
     assistantWelcomepGreetings:"Olá, eu sou o assistente Anders. Como posso ajudá-lo hoje?",
},
},
//poland
pl:{
    confirmationMessages: {
    title: 'Witaj świecie',
    description: 'To przykład prostej aplikacji React z wieloma językami.',
    workTitle: 'Deweloper',
    cityName: 'Göteborg',
    countryName: 'Szwecja',
    aboutMeText: 'O mnie',
    backgroundText:"Jestem programistą z długim doświadczeniem w tworzeniu baz danych, back-endu i front-endu. Pracowałem z wieloma różnymi technologiami i językami przez lata. Mam pasję do nauki nowych rzeczy i zawsze szukam nowych wyzwań.",
    chatWithAnders: 'Rozmawiaj z asystentem Andersa',
    sendButtonText: 'Wyślij',
    skillsText: 'Umiejętności',
    projectsText: 'Projekty',
    experienceText: 'Doświadczenie',
    photoGalleryText: 'Galeria zdjęć',
    recetProjectText:"Ostatni projekt - kompletny system www.bakesoft.se",
    crmText:"CRM - systemy duże firmy",
    aiInfoText:"AI - informacje, konwersja, agenci i wywołania backend w OpenAi",
    hellstromInfo1:"Hellström Trading www.bakesoft.se 2022 - 2024",
    hellstromInfo2:"Rozwój baz danych i front-end Hellström Trading",
    hellstromInfo3:"Nauczyciel angielskiego Japonia 2016 - 2019",
    hellstromInfo4:"Kierownik biura i zakupów Canton 2011-2014",
    hellstromInfo5:"Trend Interior -Data systems Manager 2004 - 2010",
    hellstromInfo6:"Stroede IT Development - Rozwój systemów i kierownik projektu  1999 - 2005", 
    projectsText: 'Projekty',
    photoGalleryText: 'Galeria zdjęć',
    weatherInText: 'Pogoda w',
    temperatureText: 'Temperatura',
    conditiontext: 'Stan',
     assistantWelcomepGreetings:"Cześć, jestem asystentem Andersa. Jak mogę ci dzisiaj pomóc?",
},
},
};




export default languages;