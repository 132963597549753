
import React,{useEffect,useState,useRef} from "react";
import "./styles/newMain.css";
import { Github, Linkedin, Mail } from "lucide-react"
import bakesoft from "./images/bakesoft.jpg"
import { HiOutlineLightBulb } from "react-icons/hi";
import andersblabar from "./images/andersblabar.jpg"
import andersJapan from "./images/andersJapan.jpg"
import andersvarberg from "./images/andersvarberg.jpg"
import andersVisingso from "./images/andersVisingso.jpg"
import japananderstokyostation from "./images/japananderstokyostation.jpg"
import tokyostation from "./images/tokyostation.jpg"
import tokyo1 from "./images/tokyo1.jpg"
import lastsupper from "./images/lastsupper.jpg"
import japananderstokyostation2 from "./images/japananderstokyostation2.jpg"
import surdegbrod from "./images/surdegbrod.jpg"
import languages from "./AppLanguage";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import{usePostData1} from "./useGetData";
import axios from "axios";
import {WiDaySunny} from "weather-icons-react";
import {WiCloud} from "weather-icons-react";
import {WiDayRain} from "weather-icons-react";
import {WiRain} from "weather-icons-react";
import {WiThunderstorm} from "weather-icons-react";
import {WiSnow} from "weather-icons-react";
import { FaMobile } from "react-icons/fa";
import { US } from 'country-flag-icons/react/3x2'
import { SE } from 'country-flag-icons/react/3x2'
import { JP } from 'country-flag-icons/react/3x2'
import { GB } from 'country-flag-icons/react/3x2'
import {DK} from 'country-flag-icons/react/3x2'
import {NO} from 'country-flag-icons/react/3x2'
import {FI} from 'country-flag-icons/react/3x2'
import {DE} from 'country-flag-icons/react/3x2'
import {FR} from 'country-flag-icons/react/3x2'
import {IT} from 'country-flag-icons/react/3x2'
import {ES} from 'country-flag-icons/react/3x2'
import {PT} from 'country-flag-icons/react/3x2'
import {NL} from 'country-flag-icons/react/3x2'
import {BE} from 'country-flag-icons/react/3x2'



export default function Home() {

    const [windowWidth, setWindowWith] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [mobileIsUsed, setMobileIsUsed] = useState(false);
    const [detectedLanguage, setDetectedLanguage] = useState('en');
    const [country, setCountry] = useState('en');
    const getCountry = country || detectedLanguage;
  let assistantWelcomepGreetings1="";
    if(detectedLanguage==="se"){
      assistantWelcomepGreetings1="Hej, jag är Anders online assistent, jag kan svara på frågor om Anders"
    }else if (detectedLanguage==="ja"){
      assistantWelcomepGreetings1="こんにちは、私はアンダースのオンラインアシスタントです。アンダースに関する質問にお答えします"
    }else if (detectedLanguage==="en"){
      assistantWelcomepGreetings1="Hello, i am anders online assistant,i can answer questions about Anders"
    }
    const [messages, setMessages] = useState([{ sender: "assistant", text: assistantWelcomepGreetings1 }]);
    const [userMessage, setUserMessage] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [showChat, setShowChat] = useState(false);
  
  
  
  
    useEffect(() => {
      function setSize() {
        setWindowWith(window.innerWidth);
        setWindowHeight(window.innerHeight);
       // alert(window.innerWidth + 'x' + window.innerHeight);
        if(window.innerWidth < 600){
          setMobileIsUsed(true)
        }
      }
      // Call setSize initially to set the correct state
      setSize();
      // Add the event listener
      window.addEventListener('resize', setSize);
      // Return a cleanup function to remove the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', setSize);
      };
    }, []); // Dependencies
    
    // language settings for the user at arrival
    useEffect(() => {
      const detectLanguage = () => {
        const userLanguage = navigator.language || navigator.userLanguage;
        if (userLanguage.startsWith('sv')) {
          setCountry('en')
          return 'en'; // Swedish detected
        } else if (userLanguage.startsWith('en')) {
          setCountry('en')
          return 'en'; // English detected
        } else if (userLanguage.startsWith('ja')) {
          setCountry('ja')
          return 'ja'; // Japanese detected
        } else {
          return 'en'; // Default to English if no match
        }
   
      };
    
      const detectedCountry = detectLanguage();
      setDetectedLanguage(detectedCountry);
    }, []);
    
    
    // language settings for the applanguage
    const selectedCountry = languages[getCountry];
  
    const{
      workTitle,
      cityName,
      countryName,
      aboutMeText,
      backgroundText,
      chatWithAnders,
      sendButtonText,
      skillsText,
      projectsText,
      experienceText,
      photoGalleryText,
      recetProjectText,
      crmText,
      aiInfoText,
      hellstromInfo1,
      hellstromInfo2,
      hellstromInfo3,
      hellstromInfo4,
      hellstromInfo5,
      hellstromInfo6,
      weatherInText,
      temperatureText,
      conditiontext,
      assistantWelcomepGreetings,
    
  
    }=selectedCountry.confirmationMessages||{};
  
  
    const [profileImage, setProfileImage] = useState('');
    const [partChoice, setPartChoice] = useState(1);
    useEffect(() => {
      const fetchProfileImage = async () => {
          try {
              // Replace 'your-username' with your actual GitHub username
              const response = await fetch('https://api.github.com/users/anhe00');
              const data = await response.json();
              setProfileImage(data.avatar_url);
          } catch (error) {
              console.error('Error fetching GitHub data:', error);
          }
      };
  
      fetchProfileImage();
  }, []);
  
  const setPartChoiceHandler = (choice) => {
    setShowChat(false);
    setPartChoice(choice);
  }
  
  
  const HandleUserMessage = async (e) => {
    e.preventDefault();
    if (!userMessage.trim()) return;
    AndersAssistant(userMessage);
  };
  
  
  async function AndersAssistant(userMessage) {
  
    const newMessage = { sender: "user", text: userMessage };
   //setMessages([...messages, newMessage]);
    setUserMessage("");
    setIsTyping(true);
    let apiKey=process.env.REACT_APP_ANDERSASSISTANT
    try {
      
      let response=await usePostData1(`andersassistant`,{userMessage},apiKey);
      setMessages((prevMessages) => [
        ...prevMessages,
        newMessage,
        { sender: "assistant", text: response.data.message},
      ]);
    } catch (error) {
      console.error("Error:", error);
    }
    setIsTyping(false);
  }
  
  
  const chatContainerRef = useRef(null);
  const lastMessageRef = useRef(null);
  
  useEffect(() => {
    // Scroll the chat container to the bottom whenever messages are updated
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isTyping]);
  
  const setShowChatHandler = () => {
    setShowChat(prev => !prev);
    setMessages([{ sender: "assistant", text: assistantWelcomepGreetings}]);
    }
    
    // choice for country
    const countryHandler=(country)=>{
      setCountry(country)
      setMessages([{ sender: "assistant", text: assistantWelcomepGreetings}]);
    }
  
  
    function parseMessageWithLinks(message) {
      // Regular expression to detect URLs
      const urlRegex = /https?:\/\/[^\s]+/g;
  
      // Replace URLs with anchor tags
      return message.replace(urlRegex, (url) => {
          return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
  }

  return(
<div className="mainTopArea">
    <div className="mainTotalArea">
        <div className="mainHeaderWrapper">
        <div className="mainHeaderTitleText">Anders Hellström</div>
        <div className="mainHeaderSubTitleText">{workTitle}</div>
        <div className="mainHeaderSubTitleTextLocation">{cityName}, {countryName} &nbsp;&nbsp; <FaMobile/> +46792143182</div>
    
        <div className="mainWeatherWrapper">
          <Weather
             weatherInText={weatherInText}
             temperatureText={temperatureText}
             conditiontext={conditiontext}

          />
        </div>
        <div className="mainFlagsWrapper">
              <Flags countryHandler={countryHandler} />
            </div>

        </div>
        <div className="mainAreaB">
          <div className="mainAreaBWrapper">
            <div className="mainAboutMeText">{aboutMeText}</div>
       
                  <div className="mainMidPhoto">
                    <img src={profileImage} alt="Anders Hellström"
                                       className="mainMidPhotoImg"/>
                  </div>
                  <div className="mainMidInfoText">
                  {backgroundText}
                </div>
            <div className="mainMidButtonsWrapper">
                  <div className="mainInfoButton">
                         <Github  className="mainIcons"/>
                               <div className="mainIconText"
                                onClick={()=>window.location.href="https://www.github.com/anhe00"}
                               >Github</div>
                   </div>
                        <div className="mainInfoButton"
                              onClick={() => window.location.href = "mailto:anders.stanley@gmail.com"}
                                >
                              <Mail  className="mainIcons"/>
                                      <div className="mainIconText"
                                      >Email</div>
                        </div>
                        <div className="mainInfoButtonChat"
                         onClick={()=>setShowChatHandler(!showChat)}
                  >
                      <IoChatboxEllipsesOutline className="mainIcons" />
                               <div className="mainIconTextChat"
                               >{chatWithAnders}</div>
                  </div>
                  {showChat && (
            <div className="chat-container">
                <div className="chat-messages" ref={chatContainerRef} id="chat-container">
                    {messages.map((msg, index) => (
                        <div key={index} className={`message ${msg.sender}`}>
                            {/* Render message with links as HTML */}
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: parseMessageWithLinks(msg.text),
                                }}
                            />
                        </div>
                    ))}
                    {isTyping && (
                        <div className="message assistant typing-indicator">
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                        </div>
                    )}
                    {/* Dummy div to scroll to */}
                    <div ref={lastMessageRef}></div>
                </div>

                <form onSubmit={HandleUserMessage} className="message-form">
                    <input
                        type="text"
                        placeholder="Type a message..."
                        value={userMessage}
                        onChange={(e) => setUserMessage(e.target.value)}
                    />
                    <button type="submit">{sendButtonText}</button>
                </form>
            </div>
        )}
              
          
              

            </div>
            <div className="mainMidSelectWrapper">
                  <div className="mainMidSelectBox">
                    <div className={partChoice===1?"mainMidSelectButtonON":"mainMidSelectButton"}
                    onClick={()=>setPartChoiceHandler(1)}>{skillsText}</div>
                    <div className={partChoice===2?"mainMidSelectButtonON":"mainMidSelectButton"}
                    onClick={()=>setPartChoiceHandler(2)}
                    >{projectsText}</div>
                    <div className={partChoice===3?"mainMidSelectButtonON":"mainMidSelectButton"}
                    onClick={()=>setPartChoiceHandler(3)}
                    >{experienceText}</div>
                    <div className={partChoice===4?"mainMidSelectButtonON":"mainMidSelectButton"}
                    onClick={()=>setPartChoiceHandler(4)} 
                    >{photoGalleryText}</div>
                    
                  </div>
                </div>
          </div>
        </div>



            <div className="mainAreaC">
            <div className="mainMidInfoWrapper">
        <div className="mainMidInfoBox">
          {partChoice === 1 && <MidSkills />}
          {partChoice === 2 && <Projects 
            recentProjectText={recetProjectText}
            crmText={crmText}
            aiInfoText={aiInfoText}
            projectsText={projectsText}
          
          
          />}
          {partChoice === 3 && <Experience 
             experienceText={experienceText}
            hellstromInfo1={hellstromInfo1}
            hellstromInfo2={hellstromInfo2}
            hellstromInfo3={hellstromInfo3}
            hellstromInfo4={hellstromInfo4}
            hellstromInfo5={hellstromInfo5}
            hellstromInfo6={hellstromInfo6}
          
          />}
          {partChoice === 4 && <PhotoGallery 
          photoGalleryText={photoGalleryText}
          />}
        
        </div>

       </div>


            </div>
      </div>
    </div>
  
  )

}

function Weather(props){
  let {weatherInText,temperatureText,conditiontext}=props;

  const [weather, setWeather] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

  const API_KEY = process.env.REACT_APP_WEATHERAPI; // Ensure this is set in your .env file
    const LOCATION = 'Göteborg'; // You can make this dynamic or based on user input

    useEffect(() => {
        const fetchWeather = async () => {
            try {
                const response = await axios.get(
                    `https://api.weatherapi.com/v1/current.json?key=${API_KEY}&q=${LOCATION}`
                );
                setWeather(response.data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchWeather();
    }, [API_KEY]);


    if (loading) return <p>Loading weather data...</p>;
    if (error) return <p>Error fetching weather data: {error}</p>;

    const { current } = weather;

    // Map the weather condition to an icon
    const getWeatherIcon = (condition) => {
        switch (condition) {
            case 'Sunny':
            case 'Clear':
                return WiDaySunny;
            case 'Partly cloudy':
                return WiCloud;
            case 'Overcast':
            case 'Cloudy':
                return WiCloud;
            case 'Rain':
            case 'Light rain':
            case 'Moderate rain':
            case 'Heavy rain':
                return WiRain;
            case 'Thunderstorm':
                return WiThunderstorm;
            case 'Snow':
                return WiSnow;
            default:
                return WiDaySunny;
        }
    };

    const WeatherIcon = getWeatherIcon(current.condition.text);

    return (
        <div className="mainWeather">
            <div className="mainWeatherText">{weather.location.name}</div>
          <WeatherIcon size={48} className="mainWeatherIcon"/>
            <p>{temperatureText}{':'} {current.temp_c} °C</p>&nbsp;
            <p>{conditiontext}: {current.condition.text}</p>
        </div>
    );
};

function Flags(props){
  let {countryHandler}=props;

  return(
    <div className="mainFlagArea">
    <SE title="Swedish" className="midFlag" onClick={()=>countryHandler('se')}/>
    <GB title="Swedish" className="midFlag" onClick={()=>countryHandler('en')}/>
    {/* <JP title="Japanese" className="midFlag" onClick={()=>countryHandler('ja')}/> */}
    <US title="English" className="midFlag" onClick={()=>countryHandler('en')}/>
    <DE title="German" className="midFlag"  onClick={()=>countryHandler('de')}/>
    {/* <FR title="French" className="midFlag" onClick={()=>countryHandler('fr')}/> */}
    <IT title="Italian" className="midFlag" onClick={()=>countryHandler('it')}/>
    <ES title="Spanish" className="midFlag" onClick={()=>countryHandler('span')}/>
    <PT title="Portuguese" className="midFlag" onClick={()=>countryHandler('pt')}/>
    <NL title="Dutch" className="midFlag" onClick={()=>countryHandler('nl')}/>
    <BE title="Belgium" className="midFlag" onClick={()=>countryHandler('nl')}/>
    <NO title="Norway" className="midFlag"  onClick={()=>countryHandler('no')}/>
    <DK title="Denmark" className="midFlag" onClick={()=>countryHandler('dk')}/>
    <FI title="Finland" className="midFlag" onClick={()=>countryHandler('fi')}/>


    </div>
  )

}

function MidSkills(){

  let skills=["Javascript","T-sql","Sql","MySql","MS SQL","React","Node.js","Next.js","SASS","CSS","Tailwind","OpenAI","AWS","Paypal dev","Klarna dev","Stripe dev","Swish dev"]

  const[skillChoice,setSkillChoice]=useState();


  return(
    <div className="mainMidSkillsBoxWrapper">
    <div className="mainMidSkillsBox">
      <div className="mainMidSkillsTitle">Skills</div>
      <div className="mainMidSkillButtonWrapper">
      {skills.map((skill,index)=>(
        <div className={skillChoice===index?"mainMidSkillsButton1":"mainMidSkillsButton"} key={index}
        onClick={()=>setSkillChoice(index)}
        >{skill}</div>
      ))}
  
         </div>
    </div>

    </div>

  )
}

function Projects(props){
  let {recentProjectText,crmText,aiInfoText,projectsText

  }=props;
  
  return(
    <div className="mainProjectsBoxWrapper">
    <div className="mainProjectsBox">
      <div className="mainProjectsTitle">{projectsText}</div>
      <div className="mainProjectsButtonWrapper">
      <div className="mainProjectsList"><HiOutlineLightBulb
      className="mainProjectsIcon"
      />{recentProjectText}</div>
      <div className="mainProjectsList">
      <HiOutlineLightBulb
      className="mainProjectsIcon"
      />{crmText}</div>
      <div className="mainProjectsList">
      <HiOutlineLightBulb
      className="mainProjectsIcon"
      />
      {aiInfoText}</div>
      </div>


    </div>

   </div>
  )
}

function Experience(props){
  let {experienceText,hellstromInfo1,
    hellstromInfo2,hellstromInfo3,hellstromInfo4,hellstromInfo5,hellstromInfo6}=props;

  return(
    <div className="mainExperienceBoxWrapper">
    <div className="mainExperienceBox">
      <div className="mainExperienceTitle">{experienceText}</div>
      <div className="mainExperienceButtonWrapper">
      <div className="mainExperienceList">{hellstromInfo1}</div>
      <div className="mainExperienceList">{hellstromInfo2}</div>
      <div className="mainExperienceList">{hellstromInfo3}</div>
      <div className="mainExperienceList">{hellstromInfo4}</div>
      <div className="mainExperienceList">{hellstromInfo5}</div>
      <div className="mainExperienceList">{hellstromInfo6}</div>
      
      </div>

    </div>

   </div>
  )
}

function PhotoGallery(props){
  let {photoGalleryText}=props;
  let photoArray = [andersblabar, andersJapan, andersvarberg, andersVisingso,japananderstokyostation2,surdegbrod, tokyo1]

  const [clickedImage, setClickedImage] = useState();

  const clickedImageHandler = (index) => {
    return () => {
      if (clickedImage === index) {
        setClickedImage(null); // Close if the same image is clicked again
      } else {
        setClickedImage(index); // Open new image
      }
    };
  };



  return(
    <div className="mainPhotoGalleryBoxWrapper">
    <div className="mainPhotoGalleryBox">
      <div className="mainPhotoGalleryTitle">{photoGalleryText}</div>
      <div className="mainPhotoGalleryImageWrapper">
        {photoArray.map((photo, index) => (
          
          <div className={clickedImage===index?"mainPhotoGalleryImageBox1":"mainPhotoGalleryImageBox"} key={index}
          onClick={clickedImageHandler(index)}
          >
            <img src={photo} alt="Anders Hellström"
              className="mainPhotoGalleryImage"/>
          </div>
        ))}
      </div>
      </div>
    </div>
  )
}
export {Weather,Flags,MidSkills,Projects,Experience,PhotoGallery}